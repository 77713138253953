import axios, { AxiosResponse, AxiosRequestConfig, AxiosError } from "axios";
import { MessagePlugin, NotifyPlugin } from "tdesign-vue-next";
import CryptoJS from "crypto-js";
import { cloneDeep } from "lodash";

import { useUserStore } from "@/store/user";
import { signatureLib, signatureErrorLog } from "./requestSignatureLib";
import { sleep } from "@/utils/fn";

const { MODE, VUE_APP_API_BASE_URL, VUE_APP_PROXY_KEY } = import.meta.env;

export interface ResponseData {
    code: number;
    data: any;
    msg: string;
    tid: string;
    token?: string;
}

export type RequestError = AxiosError<{
    message?: string;
    error?: string;
    path?: string;
    status?: number;
    timestamp?: string;
}>;

// 缓存数据类
class CacheData {
    // 等待赋值
    public waiting = Symbol("waitingValue");

    private cacheMap: Map<string, { data: any; timestamp: number; expire: number }>;

    // 是否开启了检查
    private isChecking = false;

    constructor() {
        this.cacheMap = new Map();
    }

    // 生成缓存key
    static generateKey(method: string, url: string, params: any): string {
        return CryptoJS.MD5(`${method}:${url}?${JSON.stringify(params)}`).toString();
        // return `${method}:${url}?${CryptoJS.MD5(JSON.stringify(params)).toString()}`;
    }

    // 等待赋值
    public async waitForValue(key: string, timeout: number = 5000): Promise<any> {
        const startTime = Date.now();

        while (Date.now() - startTime < timeout) {
            const value = this.get(key);
            if (value !== null) {
                return value;
            }
            await sleep(100);
        }

        return null;
    }

    // 设置缓存
    public set(key: string, value: any, expire: number = 0): void {
        this.cacheMap.set(key, {
            data: value === this.waiting ? value : cloneDeep(value),
            timestamp: Date.now(),
            expire: Number(expire) * 1000,
        });

        this.cleanup();
    }

    // 获取缓存
    public get(key: string): any {
        const cache = this.cacheMap.get(key);
        if (!cache) return null;

        // 检查是否过期
        if (cache.expire > 0 && Date.now() - cache.timestamp > cache.expire) {
            this.cacheMap.delete(key);
            return null;
        }

        return cache.data === this.waiting ? cache.data : cloneDeep(cache.data);
    }

    // 删除缓存
    public delete(key: string): void {
        this.cacheMap.delete(key);
    }

    // 清空缓存
    public clear(): void {
        this.cacheMap.clear();
    }

    // 清理过期数据
    private async cleanup(isInnerLoop: boolean = false): Promise<void> {
        // 如果正在检查，则不进行检查
        if (this.isChecking && !isInnerLoop) return;
        this.isChecking = true;

        const now = Date.now();
        for (const [key, cache] of this.cacheMap.entries()) {
            if (cache.expire > 0 && now - cache.timestamp > cache.expire) {
                this.delete(key);
            }
        }

        if (this.cacheMap.size) {
            await sleep(1);
            return this.cleanup(true);
        }

        this.isChecking = false;
    }
}

// 请求拦截器类
class AxiosHandler {
    constructor() {}

    // 缓存数据
    static cacheData = new CacheData();

    // 请求拦截器
    static request(config: Required<AxiosRequestConfig>): AxiosRequestConfig | Promise<AxiosRequestConfig> {
        let userStore = useUserStore();

        if (userStore.token) {
            config.headers.Authorization = userStore.token;
        }

        if (MODE === "development" && !config.url.startsWith("http")) {
            config.url = (config.url.startsWith("/") ? VUE_APP_PROXY_KEY : VUE_APP_PROXY_KEY + "/") + config.url;
        }

        return signatureLib(config as any);
    }

    // 响应拦截器
    static response(response: AxiosResponse): ResponseData {
        let result = response.data;

        // 调试代码，用于快速发现接口签名错误问题，稳定后可删除。 2023-01-11
        signatureErrorLog(response as any);

        // 如果该值为true，不进行处理，直接返回
        if (result.code !== 0 && response.config.headers.customError) {
            // 如果是下载文件
            if (response.config.url!.includes("web/content/download")) {
                return response as unknown as ResponseData;
            }

            throw result;
        }

        switch (result.code) {
            // 正常
            case 0:
                break;

            // 需要重新登录
            case 40100:
                MessagePlugin.error(result.msg);
                useUserStore().LOGOUT();
                throw result;

            default:
                MessagePlugin.error(result.msg);
                throw result;
        }

        // 设置缓存
        let { cacheDuration, cacheKey } = response.config.headers;
        if (cacheDuration > 0) {
            AxiosHandler.cacheData.set(cacheKey, result?.data, cacheDuration);
        }

        return result?.data;
    }

    // 异常拦截处理器
    static error(error: RequestError): Promise<any> {
        if (error.response) {
            const { data, status, statusText } = error.response;

            switch (status) {
                // case 404:
                //     break;

                default:
                    NotifyPlugin.error({
                        title: data?.error || statusText,
                        content: data?.message || (data as string),
                    });
                    break;
            }

            // 如果你需要直接跳转登录页面
            // useUserStore().LOGOUT();
        } else if (error.message) {
            NotifyPlugin.error({
                title: "网络异常",
                content: error.message,
            });
        }

        throw error;
    }
}

// 创建 axios 实例
const request = axios.create({
    // API 请求的默认前缀
    baseURL: VUE_APP_API_BASE_URL,
    timeout: 6000, // 请求超时时间
});

// 添加请求拦截器
request.interceptors.request.use(AxiosHandler.request as any, AxiosHandler.error);
// 添加响应拦截器
request.interceptors.response.use(AxiosHandler.response as any, AxiosHandler.error);

// 先利用type声明一个函数
type RequestFun = <T = any>(
    url: `/${string}` | `http${string}`,
    data?: AnyObject | null,
    headers?: {
        // 请求类型
        responseType?: string;
        // 超时时间
        timeout?: number;
        // 是否自定义处理报错
        customError?: boolean;
        // 缓存时长
        cacheDuration?: number;
    } & AnyObject,
) => Promise<T>;

// get请求参数序列化
const paramsSerializer = (params: AnyObject) =>
    Object.entries(params)
        .filter((v) => v[1] || [false, 0].includes(v[1] as any))
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(decodeURIComponent(value as string))}`)
        .join("&");

// 代理请求方法
async function quickRequest(method: string, url: string, data: Parameters<RequestFun>[1] = {}, headers: Parameters<RequestFun>[2] = {}) {
    let { timeout, responseType, ...header } = headers as any;

    // 如果需要设置缓存
    if (header.cacheDuration > 0) {
        const cacheKey = CacheData.generateKey(method, url, data);
        let cacheData = AxiosHandler.cacheData.get(cacheKey);

        header.cacheKey = cacheKey;

        // 如果前面有正在请求的数据，等待前面相同的请求处理完。直接拿缓存数据。
        while (cacheData === AxiosHandler.cacheData.waiting) {
            await sleep(0.2);
            cacheData = AxiosHandler.cacheData.get(cacheKey);
        }

        // 如果有缓存
        if (cacheData) {
            // 返回缓存数据
            return Promise.resolve(cacheData);
        }

        // 第一次请求时，或缓存被删除重新请求时，加上等待赋值标记
        AxiosHandler.cacheData.set(cacheKey, AxiosHandler.cacheData.waiting, header.cacheDuration);
    }

    return request({ method, url, responseType, timeout, headers: header, [method === "get" ? "params" : "data"]: data || {}, paramsSerializer });
}

export const get: RequestFun = (url, ...args) => quickRequest("get", url, ...args) as Promise<any>;
export const post: RequestFun = (url, ...args) => quickRequest("post", url, ...args) as Promise<any>;
export const put: RequestFun = (url, ...args) => quickRequest("put", url, ...args) as Promise<any>;
export const del: RequestFun = (url, ...args) => quickRequest("delete", url, ...args) as Promise<any>;

export default request;
