// 服务构造器
let headersConstruction = (serverName: string) => ({ "app-id": serverName });

export const HEADERS_WEB = headersConstruction("web_api_yk");

export const HEADERS_AUTH = headersConstruction("auth_ip_yk");

export const HEADERS_CRM_AI = headersConstruction("crm_ai_yk");

export const HEADERS_COMMON = headersConstruction("common_ip_yk");
